<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2" @click="informationernaehrungzuinformation()">
            <img src="../assets/icons/vector_back.svg">
            <!-- wenn kein Icon, img entfernen -->
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-white">Werte verstehen</p>
          </div>
          <div class="col-2 col-right">
            <!-- wenn kein Icon, img entfernen -->
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-body margintop140 container marginbottom20">
      <p class="pregular fontcolor-white opacity80">Nach der TX tragen Sie eine hohe Eigenverantwortung für Ihre neue Niere. Bitte beachten Sie folgendes:</p>
    </div>

    <div class="container paddingbottom110">
      <div class="accordion" role="tablist">
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">
              <p class="psemibold fontcolor-white">
                Blutdruck
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Normalwert: <br>
                Wunschwert: Unter 130/85mmHg
              </p>
            </b-card-body>
          </b-collapse>
          <div></div>
        </b-card>
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info">
              <p class="psemibold fontcolor-white">
                Erythrozyten (Rote Blutzellen)
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                ... sind für den Sauerstofftransport verantwortlich.<br><br>
                Normalwert: w: 11 – 15 g/dl, m: 13 – 17 g/dl
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info">
              <p class="psemibold fontcolor-white">
                Harnstoff
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Ist ein Abbauprodukt des Eiweißstoffwechsels und wird über die Niere ausgeschieden. Bei nachlassender Nierenfunktion kann ein erhöhter Anteil im Blut festgestellt werden.<br><br>
                Normalwert: 23 – 53 mg/dl
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info">
              <p class="psemibold fontcolor-white">
                Kalium
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Ist wichtig für die Nerven- und Muskelfunktion und wird über die Niere und in geringem Umfang auch über den Darm ausgeschieden. Ist der Kaliumwert zu hoch oder zu niedrig, besteht die Gefahr von Herzrhythmusstörungen und Lähmungserscheinungen. Funktioniert die transplantierte Niere schützt sie vor ausgeprägten Kaliumschwankungen.<br><br>
                Normalwert: 3,8 – 5,2 mMol/l
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info">
              <p class="psemibold fontcolor-white">
                Kalzium
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Ist essenziell für den Knochenaufbau und die Funktion der Muskelkontraktionen. Ein zu geringer Wert kann Muskelkrämpfe und ein Kribbelgefühl um den Mund und den Händen oder Füßen auslösen.<br><br>
                Normalwert: gesamt: 2,2 – 2,6 mMol/l, ionisiert: 1,1 – 1,35 mMol/l
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-6 variant="info">
              <p class="psemibold fontcolor-white">
                Kreatinin
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                ...ist ein Abbauprodukt aus dem Muskelstoffwechsel und wird über die Niere ausgeschieden. Bei nachlassender Nierenfunktion kann ein erhöhter Kreatininanteil im Blut festgestellt werden.<br><br>
                Normalwert: w: 0,5 – 1,0 mg/dl, m: 0,6 – 1,2 mg/dl
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-7 variant="info">
              <p class="psemibold fontcolor-white">
                Körpergewicht
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Morgens vor dem Frühstück zu immer gleichen Bedingungen messen. Eine Gewichtszunahme von mehr als 1-2 kg an einem Tag oder 2-3 kg pro Woche kann bedenklich sein und sollte mit dem Arzt besprochen werden.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-8 variant="info">
              <p class="psemibold fontcolor-white">
                Körpertemperatur
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Normalwert: 36°C – max. 37°C<br><br>
                Temperaturen über 37,5°C können Anzeichen einer Infektion sein. Bei Temperaturen über 38°C sollten Sie auf alle Fälle sofort einen Arzt kontaktieren.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-9 variant="info">
              <p class="psemibold fontcolor-white">
                Leukozyten (Weiße Blutzellen)
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Sind für die Immunabwehr verantwortlich. Eine zu geringe Anzahl an Leukozyten erhöht die Gefahr für Infektionen.<br><br>
                Normalwert: 4.000 – 10.000/μl
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>


        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-10 variant="info">
              <p class="psemibold fontcolor-white">
                Natrium
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                wird dem Körper durch die Nahrung in Form von Kochsalz (Natrium-Chlorid) zugeführt und von der Niere ausgeschieden. Eine zu hoher Natriumgehalt kann Auslöser für Bluthochdruck, Ödeme, Durstgefühl und Gewichtszunahme sein.<br><br>
                Normalwert: 136 – 144 mMol/l
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-11 variant="info">
              <p class="psemibold fontcolor-white">
                Parathormon
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Ist für die Aufrechterhaltung der Kalziumkonzentration im Blut/Gewebe verantwortlich und ist ein Hormon der Nebenschilddrüse. Es löst Kalzium aus dem Knochen. Dauerhaft erhöhte Werte lassen die Knochen brüchig werden.<br><br>
                Normalwert: 15 – 65 ng/l
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-12 variant="info">
              <p class="psemibold fontcolor-white">
                Phosphat
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Ist essenziell für den Energiestoffwechsel der Zellen und den Aufbau von Zähnen und Knochen. Außerdem dient er als Energielieferant und Energiespeicher und wird über die Nieren ausgeschieden. Bei einem Nierenfunktionsverlust steigt die Phosphatmenge im Blut an und lagert sich zusammen mit Kalzium in den Wänden der Blutgefäße ab. Verkalkte Gefäßwände sind die Folge.<br><br>
                Normalwert: 0,84 – 1,45 mMol/l
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-13 variant="info">
              <p class="psemibold fontcolor-white">
                Puls
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-13" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Normalwert: 60–80 Schläge/min, regelmäßig.<br><br>
                Bei unregelmäßigem oder trotz Ruhezustand über einen längeren Zeitraum anhaltenden zu hohem Puls, informieren Sie Ihren Arzt.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-14 variant="info">
              <p class="psemibold fontcolor-white">
                Thrombozyten (Blutplättchen)
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-14" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Sind für die Blutgerinnung verantwortlich.<br><br>
                Normalwert: 150 – 400 Tsd/μl
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-15 variant="info">
              <p class="psemibold fontcolor-white">
                Vitamin D
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-15" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Ist essenziell für die Aufnahme von Kalzium im Darm aus der Nahrung. Bei einem Nierenfunktionsverlust sinkt die Menge an Vitamin D.<br><br>
                Normalwert: 20 – 70 μg/l
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        
        <!-- <information-accordion accordiontitle="Vitalwerte" accordiontext="Loremipsumdolorsit"></information-accordion> -->
      </div>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<script>
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";

  export default {
    components: {
      'foot-navigation' :FootNavigationVue,
    },
    methods: {
      informationernaehrungzuinformation() {
        router.push({ path: '/information' })
      },
    },
   }; 
</script>

<style>
.accordion {
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    text-align: left;
}

.accordion .card:first-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.mb-1, .my-1 {
    margin-bottom: 0.25rem!important;
}

.p-1 {
    padding: 0rem!important;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border-radius: 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.card-header:first-child {
    border-radius: 0 !important;
}

.card-header {
    padding: 0.75rem 0rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.btn {
    background: transparent;
    border: 0;
    outline: none;
    border-radius: 30px;
    display: inline-block;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 1.3rem 0rem;
    font-size: 1rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-info {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-info.focus, .btn-info:focus {
    box-shadow: none !important;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0rem 0rem 1.5rem 0rem;
}

</style>